var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "outer" }, [
    _c(
      "header",
      [
        _c("el-input", {
          attrs: { placeholder: "Search projects", size: "mini" },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v
            },
            expression: "search",
          },
        }),
        _c(
          "el-button",
          { attrs: { size: "mini" }, on: { click: _vm.addProject } },
          [_vm._v(" New ")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "inner" }, [
      _c(
        "ul",
        _vm._l(_vm.filtered_projects, function (project) {
          return _c(
            "li",
            {
              key: project.id,
              on: { click: () => _vm.navigateToProject(project) },
            },
            [
              _c("span", [_vm._v(_vm._s(project.name))]),
              _c("svgicon", {
                staticClass: "triangle",
                attrs: { name: "triangle" },
              }),
            ],
            1
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }